import { Grid, InputAdornment, TextField, TextFieldProps, WithStyles } from '@material-ui/core';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, forwardRef, ReactNode } from 'react';

import { YojeeTheme } from '../../theme';
import { getClassNameByRule } from '../../utils';

export type InputProps = Omit<TextFieldProps, 'variant'> & {
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning';
  InputProps?: TextFieldProps['InputProps'];
  icon?: ReactNode;
  extraLabel?: ReactNode;
  afterLabel?: ReactNode;
  extraLabelAlignment?: 'right';
  helperText?: ReactNode;
  label: ReactNode;
  labelRootClassName?: string;
  required: boolean;
  inputProps?: TextFieldProps['inputProps'];
  dataCy?: string;
};

const InputStyles = (theme: YojeeTheme) =>
  createStyles({
    root: {
      borderRadius: 5,
      height: theme.spacing(5),
      fontSize: '0.875rem',
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline, &.Mui-disabled .manage-MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px !important',
      },
      '&.manage-MuiInputBase-multiline': {
        padding: 0,
      },
    },
    rootMultiline: {
      height: '100%',
      padding: 0,
    },
    input: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      '.MuiInputAdornment-root + &': {
        paddingLeft: theme.spacing(1),
      },
    },
    inputMarginDense: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },
    inputAdornedStart: {
      paddingLeft: theme.spacing(1),
    },
    labelRoot: (props: InputProps) => ({
      color: theme.palette?.black?.['B60'],
      fontSize: '0.75rem',
      fontWeight: 600,
      '& .MuiInputLabel-asterisk': {
        color: props.error ? theme.palette?.error?.main : 'inherit',
      },
      '& + div': {
        marginTop: theme.spacing(1),
      },
      '&.Mui-focused': {
        color: theme.palette?.black?.['B60'],
      },
    }),
    labelShrink: {
      transform: `none !important`,
      '&.MuiInputLabel-outlined': {
        transform: `none`,
      },
    },
    labelFormControl: {
      position: 'static',
    },
    helperTextContained: {
      marginLeft: 0,
    },
    colorSecondary: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.secondary?.main,
      },
    },
    colorError: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.error?.main,
      },
    },
    colorInfo: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.info?.main,
      },
    },
    colorSuccess: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.success?.main,
      },
    },
    colorWarning: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.warning?.main,
      },
    },
    extraLabelAlignmentRight: {
      textAlign: 'right',
      pointerEvents: 'auto',
      fontSize: '0.75rem',
    },
    disabled: {
      '&.manage-MuiOutlinedInput-root, &.MuiOutlinedInput-root': {
        backgroundColor: theme.palette?.action?.disabledBackground,
      },
    },
    asterisk: (props: InputProps) => ({ color: props.error ? theme.palette?.error?.main : 'inherit' }),
  });

const Input: FC<InputProps & WithStyles<typeof InputStyles>> = forwardRef(
  (
    {
      classes,
      color,
      InputProps,
      icon,
      extraLabel = null,
      extraLabelAlignment = 'right',
      helperText,
      label,
      labelRootClassName = '',
      required = false,
      inputProps,
      afterLabel,
      ...other
    },
    ref
  ) => {
    const { error } = other;
    const colorClass = getClassNameByRule(classes, 'color', color);
    const extraAlignmentClass = getClassNameByRule(classes, 'extraLabelAlignment', extraLabelAlignment);

    const optionalIconParam = icon
      ? {
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        }
      : {};

    let labelWithExtra = label;

    if (extraLabel) {
      labelWithExtra = (
        <Grid container spacing={0}>
          <Grid item xs>
            {label}
            {required ? <span className={classes.asterisk}> *</span> : ''}
            {afterLabel}
          </Grid>
          <Grid item xs className={extraAlignmentClass}>
            {extraLabel}
          </Grid>
        </Grid>
      );
    } else if (afterLabel) {
      labelWithExtra = (
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {label}
          {afterLabel}
        </span>
      );
    }

    return (
      <>
        <TextField
          inputRef={ref}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: clsx(classes.labelRoot, labelRootClassName),
              shrink: classes.labelShrink,
              formControl: classes.labelFormControl,
            },
            className: clsx(colorClass),
          }}
          inputProps={{
            'data-cy': other.dataCy,
            ...inputProps,
          }}
          InputProps={{
            notched: false,
            classes: {
              root: clsx(classes.root, { [classes.rootMultiline]: other.multiline }),
              input: classes.input,
              disabled: classes.disabled,
              inputMarginDense: classes.inputMarginDense,
              adornedStart: classes.inputAdornedStart,
            },
            className: clsx(colorClass),
            ...optionalIconParam,
            ...InputProps,
          }}
          label={labelWithExtra}
          required={extraLabel || afterLabel ? false : required}
          {...other}
          variant="outlined"
        />
        {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
      </>
    );
  }
);

// @ts-ignore
export default withStyles(InputStyles)(Input);
