import moment from 'moment';
import { Trans } from 'react-i18next';

import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';
import { BATCH_TEMPLATE_TYPES } from '@yojee/helpers/constants';

export const OLD_TEMPLATE_CATEGORIES = {
  item: 'Item Details',
  task: 'Task Details',
  pickup: 'Pickup Details',
  dropoff: 'Dropoff Details',
  extra: 'Extra Info',
  order: 'Order Details',
  vessel: 'Vessel Information',
};

export const TEMPLATE_CATEGORIES = {
  sender: { title: <Trans>Sender details</Trans>, index: 1 },
  order: { title: <Trans>Order Details</Trans>, index: 2 },
  task: { title: <Trans>Task Details</Trans>, index: 3 },
  pickup: { title: <Trans>Pickup Details</Trans>, index: 4 },
  dropoff: { title: <Trans>Dropoff Details</Trans>, index: 5 },
  extra: { title: <Trans>Extra Info</Trans>, index: 6 },
  vessel: { title: <Trans>Vessel Information</Trans>, index: 7 },
  item: { title: <Trans>Line(s)</Trans>, index: 8 },
  container: { title: <Trans>Container details</Trans>, index: 9 },
  dangerous_goods_info: { title: <Trans>Dangerous goods details</Trans>, index: 10 },
};

export const FIELD_AND_UNIT_FIELD_MAP = {
  weight: 'weight_unit',
  height: 'height_unit',
  length: 'length_unit',
  width: 'width_unit',
  volume: 'volume_unit',
};

export const UNIT_FIELDS = ['weight_unit', 'height_unit', 'length_unit', 'width_unit', 'volume_unit'];

export const defaultOtherReason = { active: false, task_types: [] };

export class CompanyService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
    // eslint-disable-next-line no-undef
    this.googlePlacesAutoCompleteService = new google.maps.places.AutocompleteService();
  }

  updateCompanySettings = (args = {}) => {
    const {
      address,
      phone,
      taskServiceTime,
      link1Label,
      link1Url,
      link2Label,
      link2Url,
      senderLanguage,
      trackingPageLanguage,
      forwardWhitelabelling,
      isLiveTrackingEnabled,
      isSenderInfoAndAddressShown,
      notificationReplyToEmails,
      teamGroupAddresses,
      podTemplateType,
      podAppendPhotosToConnoteTemplate,
    } = args;

    const settings = {};
    settings.sender = { 'live_tracking.address.show': isSenderInfoAndAddressShown };
    if (senderLanguage) {
      settings.sender = { ...settings.sender, language: senderLanguage };
    }
    if (trackingPageLanguage) {
      settings.sender = { ...settings.sender, 'live_tracking.language': trackingPageLanguage };
    }

    settings.company = {
      company_address: address,
      company_phone: phone,
      task_service_time: taskServiceTime,
      notification_reply_to_emails: notificationReplyToEmails,
      pod_template_type: podTemplateType,
      pod_append_photos_to_connote_template: podAppendPhotosToConnoteTemplate,
      company_terms_conditions: {
        company_agreement1_label: link1Label,
        company_agreement1_url: link1Url,
        company_agreement2_label: link2Label,
        company_agreement2_url: link2Url,
      },
    };

    if (teamGroupAddresses) {
      settings.company.team_group_addresses = teamGroupAddresses;
    }

    settings.features = {
      live_tracking_map: isLiveTrackingEnabled,
    };

    if (forwardWhitelabelling !== null) {
      settings['forward_whitelabelling'] = forwardWhitelabelling;
    }

    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/settings'), { settings })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateCompanyDisplaySettings = (displaySettings) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/display_settings'), { display_settings: displaySettings })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateWorkerSettings = (workerSettingsToBeChanged = {}) => {
    const settings = {
      worker: {
        ...workerSettingsToBeChanged,
      },
    };

    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/settings'), { settings })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateTaskExceptionOtherReasonCompanySettings = (taskExceptionOtherReasonSettings) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/settings/task_exception_other_reason'), {
        task_exception_reason_settings: taskExceptionOtherReasonSettings,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getPartnershipInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partnership_info'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyTemplate = ({ type }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/template?type=${type}`))
      .then((response) => {
        const result = this.extractData(response);
        return this.mapTemplateDataToArray(result);
      })
      .catch((error) => this.handleError(error));
  };

  getTemplateDownloadUrl = ({ slug, fileType, templateType }) => {
    const endpoint =
      templateType === BATCH_TEMPLATE_TYPES.MULTIPLE_LEGS.id ? 'download_multileg_sample' : 'download_sample';
    const typeParam = templateType === BATCH_TEMPLATE_TYPES.SINGLE_TASK.id ? `&type=${templateType}` : '';
    return this.getUmbrellaApiUrl(`public/orders/${endpoint}?company_slug=${slug}&format=${fileType}${typeParam}`);
  };

  getCreditCardInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/payment/card?provider_type=stripe'))
      .then((response) => this.extractData(response))
      .catch((error) => {
        if (error.response.status === 404) {
          this.extractData({
            last4: null,
            type: null,
          });
        } else {
          this.handleError(error);
        }
      });
  };

  getSubscriptionInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/subscriptions'))
      .then((response) => {
        const {
          data: { subscriptions },
        } = this.extractData(response);
        return (subscriptions && subscriptions[subscriptions.length - 1]) || {};
      })
      .catch((error) => this.handleError(error));
  };

  getPlans = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/payments/stripe/plans'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getSubscriptionHistory = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/subscriptions/invoices'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  cancelSubscription = ({ id }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/subscriptions/${id}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateCompanyTemplate = ({ template, type }) => {
    const payload = template.reduce((fields, currentField, currentIndex) => {
      if (currentField && currentField['key']) {
        currentField['position'] = currentIndex;
        fields[currentField['key']] = currentField;
      }
      return fields;
    }, {});

    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/company/template`), {
        fields_schema: payload,
        type,
      })
      .then((response) => {
        const result = this.extractData(response);
        return this.mapTemplateDataToArray(result);
      })
      .catch((error) => this.handleError(error));
  };

  //  service type APIs
  getServiceTypes = (payload = {}) => {
    const { q = '' } = payload;
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/service_types?q=${q}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateServiceType = (data) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrl(`dispatcher/service_types/${data.id}`),
        this.transformCreateUpdateServiceTypeRequestData(data)
      )
      .then((response) => {
        const result = this.extractData(response);
        return this.mapTemplateDataToArray(result);
      })
      .catch((error) => this.handleError(error));
  };

  createServiceType = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/service_types'), this.transformCreateUpdateServiceTypeRequestData(data))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  deleteServiceType = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/service_types/${id}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getServiceTypeDetail = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/service_types/${id}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  //  task type APIs
  getTaskTypes = ({ company_slug }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/task_type_mappings'), { company_slug })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : [];
      })
      .catch((error) => this.handleError(error));
  };

  updateTaskType = (data) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrl(`dispatcher/company/task_type_mappings/${data.id}`),
        this.transformCreateUpdateTaskTypeRequestData(data)
      )
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  createTaskType = (data) => {
    return this.authService
      .post(
        this.getUmbrellaApiUrl('dispatcher/company/task_type_mappings'),
        this.transformCreateUpdateTaskTypeRequestData(data)
      )
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  deleteTaskType = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/task_type_mappings/${id}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  //  helper functions

  transformCreateUpdateTaskTypeRequestData = (data) => {
    if (!data) {
      return null;
    }
    const payload = {};
    if (data.name) {
      payload['display_name'] = data.name;
    }
    if (data.markerText) {
      payload['marker_text'] = data.markerText;
    }
    if (data.color) {
      payload['marker_color'] = data.color;
    }
    return payload;
  };

  transformCreateUpdateServiceTypeRequestData = (data) => {
    if (!data) {
      return null;
    }
    const payload = data.id
      ? {}
      : {
          //  for now, FE will default isolate_tasks to false when creating new service type
          isolate_tasks: false,
        };

    payload['operation_id'] = data['operation_id'] > 0 ? data['operation_id'] : null;

    if (Object.hasOwn(data, 'nonOperatingDays')) {
      payload['non_operating_days'] = data.nonOperatingDays;
    }
    if (data.name) {
      payload['name'] = data.name.trim();
    }
    if (Object.hasOwn(data, 'point2point')) {
      payload['point2point'] = data.point2point;
    }
    if (data.calculate) {
      payload.commit_time_settings = {
        calculate: data.calculate,
        cutoff_time: moment(data.cutoff_time, 'H:mm').format('HH:mm'),
        day_offset: data.day_offset,
        business_days: data.business_days,
      };
    }
    payload['special_action'] = data.special_action;
    payload['sender_link_type'] = data.sender_link_type;
    payload['linked_sender_ids'] = data.linked_sender_ids;
    return payload;
  };

  mapTemplateDataToArray = (apiData) => {
    if (
      !apiData ||
      !apiData['data'] ||
      !apiData['data']['fields_schema'] ||
      apiData['data']['fields_schema'].length < 1
    ) {
      return [];
    }
    const fields = Object.values(apiData['data']['fields_schema']);
    const categoryKeys = Object.keys(OLD_TEMPLATE_CATEGORIES);
    fields.sort((a, b) => {
      const categoriesDifference = categoryKeys.indexOf(a.category) - categoryKeys.indexOf(b.category);
      return categoriesDifference === 0 ? parseInt(a.position) - parseInt(b.position) : categoriesDifference;
    });
    return fields;
  };

  getGooglePlaceAutoComplete = (input) => {
    return new Promise((resolve, reject) => {
      const requestParam = { input };
      this.googlePlacesAutoCompleteService.getPlacePredictions(requestParam, (results, status) => {
        // eslint-disable-next-line no-undef
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results.map((address) => ({ address1: address.description })));
        } else {
          resolve([]);
        }
      });
    });
  };

  getAddressSuggestionFromGoogleAndAddressBook = (input) => {
    const addressBookPayload = { type: 'Pickup', query: input };
    const promise1 = this.getCompanyAddressBookEntries(addressBookPayload).catch(() => []);
    const promise2 = this.getGooglePlaceAutoComplete(input);
    return Promise.all([promise1, promise2])
      .then((ww) => {
        return {
          addressBook: ww[0].data || [],
          googleMap: ww[1] || [],
        };
      })
      .catch(() => ({}));
  };

  getCompanyAddressBookEntries = (payload = null) => {
    const requestPayload = this.transformDataRequestAddressBookEntries(payload);
    return this.authService
      .get(this.getUmbrellaApiUrl('sender/address_items'), requestPayload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  transformDataRequestAddressBookEntries = (data) => {
    if (!data) {
      return {
        page: 1,
        page_size: 5,
      };
    }
    const requestData = {
      page: data.pageNo || 1,
      page_size: data.pageSize || 5,
    };

    if (data.category) {
      requestData['category'] = data.category;
    }
    if (data.senderOrganisationSlug) {
      requestData['sender_organisation_slug'] = data.senderOrganisationSlug;
    }
    if (data.query) {
      requestData['query'] = data.query;
    }

    return requestData;
  };
}

export const companyService = new CompanyService({ authService });
