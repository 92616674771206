import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import DefaultFallback from './fallbacks/DefaultFallback';

const defaultResetKeys = [];

const RealErrorBoundary = ({
  children,
  resetKeys = defaultResetKeys,
  CustomFallbackComponent = DefaultFallback,
  onReset,
  setTrackPathChange,
}) => {
  const errorHandler = (error, info) => {
    setTrackPathChange?.(true);
    console.error('Error handled by Error Boundary', error, info);

    const crash = new Error('UI crash');
    crash.stack = info.componentStack;
    crash.cause = error;

    // Datadog RUM is initialized through Google Tag Manager as the app is loaded,
    // it is then assigned to the 'window' object as a global variable.
    // See https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm
    // for details on its usage.
    if (window.DD_RUM) {
      window.DD_RUM.addError(crash);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={CustomFallbackComponent}
      onError={errorHandler}
      onReset={onReset}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};

export default RealErrorBoundary;
