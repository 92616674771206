import { Trans } from 'react-i18next';

import { CW1IntegrationProfile, IntegrationProfile, IntegrationType } from '../components/Integrations/types';

export type State = {
  loading: {
    getIntegrationTypes: boolean;
    updateIntegrationProfile: boolean;
    createIntegrationProfile: boolean;
    getProfileDetails: boolean;
  };
  integrationTypes: IntegrationType[];
  profileDetails: Partial<IntegrationProfile>;
  cw1ProfileDetails: Partial<CW1IntegrationProfile>;
};

export const defaultState: State = {
  loading: {
    getIntegrationTypes: false,
    updateIntegrationProfile: false,
    createIntegrationProfile: false,
    getProfileDetails: false,
  },
  integrationTypes: [],
  profileDetails: {},
  cw1ProfileDetails: {},
};

export const integrationMessagesSuccessEvents = {
  UPDATE_INTEGRATION_PROFILE_SUCCESSFULLY: () => <Trans>Integration profile updated successfully!</Trans>,
  CREATE_INTEGRATION_PROFILE_SUCCESSFULLY: () => <Trans>Integration profile created successfully!</Trans>,
};

export const integrationMessagesErrorEvents = {
  GET_INTEGRATION_TYPES_FAILED: ({ error }: { error: { message: string } }) =>
    error.message || <Trans>Failed to get integration profiles</Trans>,
  GET_PROFILE_DETAILS_FAILED: ({ error }: { error: { message: string } }) =>
    error.message || <Trans>Failed to get profile details</Trans>,
  UPDATE_INTEGRATION_PROFILE_FAILED: ({ error }: { error: { message: string } }) =>
    error.message || <Trans>Failed to update integration profile</Trans>,
  CREATE_INTEGRATION_PROFILE_FAILED: ({ error }: { error: { message: string } }) =>
    error.message || <Trans>Failed to create integration profile</Trans>,
};

const ACTION_HANDLERS = {
  GET_INTEGRATION_TYPES_LOADING: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
  GET_INTEGRATION_TYPES_SUCCESSFULLY: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  GET_INTEGRATION_TYPES_FAILED: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  GET_PROFILE_DETAILS_LOADING: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
  GET_PROFILE_DETAILS_SUCCESSFULLY: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  GET_PROFILE_DETAILS_FAILED: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  UPDATE_INTEGRATION_PROFILE_LOADING: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
  UPDATE_INTEGRATION_PROFILE_SUCCESSFULLY: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  UPDATE_INTEGRATION_PROFILE_FAILED: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  CREATE_INTEGRATION_PROFILE_LOADING: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
  CREATE_INTEGRATION_PROFILE_SUCCESSFULLY: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  CREATE_INTEGRATION_PROFILE_FAILED: (state: State, { key }: { key: keyof State['loading'] }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  },
  UPDATE_INTEGRATION_DATA: (state: State, { type, ...restSagaData }: { type: string }) => {
    return {
      ...state,
      ...restSagaData,
    };
  },
};

const reducer = (state = defaultState, action: { type: keyof typeof ACTION_HANDLERS }) => {
  const handler = ACTION_HANDLERS[action.type];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return handler ? handler(state, action as any) : state;
};

export default reducer;
